<template>
<n-alert v-if="marketplace.localization['marketplace/my_mentors/info']" class="rounded-lg" type="warning" size="large">
    <div
        class="notes text-xs"
        v-html="marketplace.localization['marketplace/my_mentors/info']">
    </div>
</n-alert>

<div v-if="init">
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :pager="marketplace.myMentorsPager"
        :columns="marketplace.myMentorsColumns"
        :records="marketplace.myMentorsRecords"
        columnsStorageName="__columns_marketplace-my-mentors"
        type="marketplace.my_mentors"
        @getData="getData"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2">
                {{ marketplace.localization['marketplace/my_mentors/grid/title'] }}:
                <span class="text-main font-semibold text-lg">{{ marketplace.myMentorsTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<base-modal-desktop
    v-if="questionInfo"
    lightDark
    :label="`#${questionInfo.id} - ${questionInfo.subject}`"
    v-model:show="showQuestionInfo">
    <template #default>
        <div class="notes" v-html="questionInfo.question"></div>
        <n-alert
            v-if="questionInfo.state === 'confirmed' || questionInfo.state === 'expired'" 
            lass="rounded-lg" type="warning" size="large">
            <div
                class="notes text-xs"
                v-html="marketplace.localization['marketplace/mentors/dialogs/mentorship_done_warning']">
            </div>
        </n-alert>
        
        <div
            v-if="questionInfo.state === 'confirmed' || questionInfo.state === 'expired'"
            class="flex items-top mt-6">
            <div class="text-md whitespace-nowrap pr-6 pt-1">{{ marketplace.localization['marketplace/mentors/dialogs/rate_your_mentor'] }}</div>
            <n-slider
                v-model:value="mentorRate"
                step="mark"
                :min="1"
                :max="5"
                :marks="marks" />
          </div>
    </template>
    <template #footer v-if="questionInfo.state === 'confirmed' || questionInfo.state === 'expired'">
      <div class="w-full flex justify-end items-center">
        <rb-checkbox
            :label="marketplace.localization['marketplace/mentors/dialogs/license_agreement']"
            v-model:checked="questionInfoAgree" />
        <!-- :color="questionInfo.state === 'confirmed' ? gl.mainColor : '#dc3545'" -->
        <n-button
            strong
            class="rounded-md text-white/90 ml-4"
            :color="gl.mainColor"
            :loading="questionInfoLoading"
            :disabled="buttonDisabled || questionInfoLoading"
            @click="requestQuestionInfo">
            {{ marketplace.localization['marketplace/my_mentors/grid/mentorship_done'] }}
        </n-button>
      </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NSpin,
    NTabs,
    NAlert,
    NModal,
    NInput,
    NSlider,
    NButton,
    NTabPane,
    NCheckbox,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import SuperTable from '@components/super-table/desktop.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

export default {
    name: 'MyMentors',
    components: {
        NSpin,
        NIcon,
        NCard,
        NTabs,
        Editor,
        NAlert,
        NModal,
        NInput,
        NSlider,
        NButton,
        NTabPane,
        NCollapse,
        NCheckbox,
        SuperTable,
        NCollapseItem,
        Dismiss16Filled,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>